<template>
  <v-container>
    <v-textarea
      :value="text"
      disabled
      filled
      outlined
      label="Consola"
      hint="Aquí se mostrarán los mensajes durante el proceso de carga masiva"
      persistent-hint
    />
  </v-container>
</template>

<script>
  export default {
    name: 'Console',
    props: {
      text: {
        type: String,
        default () {
          return ''
        },
      },
    },
  }
</script>
