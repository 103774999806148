<template>
  <div>
    <v-btn
      color="primary"
      :disabled="nextButtonDisabled"
      @click="$emit('click')"
    >
      Siguiente
    </v-btn>
    <v-btn
      v-if="showBack"
      color="secondary"
      :disabled="prevButtonDisabled"
      @click="$emit('back')"
    >
      Atrás
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'StepperFooter',
    props: {
      nextButtonDisabled: {
        type: Boolean,
        default () {
          return false
        },
      },
      showBack: {
        type: Boolean,
        default () {
          return true
        },
      },
      prevButtonDisabled: {
        type: Boolean,
        default () {
          return false
        },
      },
    },
  }
</script>
