<template>
  <v-container class="text-center">
    <v-btn
      color="secondary"
      :href="templateHref"
    >
      Descargar la plantilla
    </v-btn>
  </v-container>
</template>

<script>
  export default {
    name: 'DownloadFile',
    props: {
      templateHref: {
        type: String,
        default () {
          return '/wm-bulk-load.template.xlsx'
        },
      },
    },
  }
</script>
